@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap");

@import "~bootstrap/scss/bootstrap";

body {
	margin: 0px !important;
	padding: 0px !important;
	background-color: #ffffff;
	font-family: "Nunito Sans", sans-serif !important;
}

.container {
	display: flex;
	flex-direction: column;
	margin: auto;
	max-width: 576px;
	width: 100%;
	min-height: 100vh;
	padding: 0px !important;
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

::-webkit-scrollbar {
	width: 0px;
}

a:hover {
	text-decoration: initial !important;
}

.MuiOutlinedInput-root {
	background: #f9fafa !important;
}

.MuiInputLabel-outlined {
	color: #2f3435 !important;
}

.MuiOutlinedInput-input {
	padding: 30px 16px 12px 16px !important;
	font-size: 14px !important;
	line-height: 22px !important;
	font-family: "Nunito Sans", sans-serif !important;
	font-weight: 600 !important;
	color: #2f3435 !important;
}

.MuiInput-input {
	color: #101415 !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 20px !important;
}

.MuiInputLabel-shrink {
	color: #727778 !important;
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 18px !important;
}

.MuiTextField-root label {
	color: #727778 !important;
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 18px !important;
}

.MuiOutlinedInput-notchedOutline {
	border: 1px solid #e5e9ea !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #83888a !important;
	border-radius: 8px !important;
	color: #2f3435 !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	font-family: "Nunito Sans", sans-serif !important;
	color: #83888a !important;
	font-weight: 400 !important;
	font-size: 10px !important;
	line-height: 16px !important;
	transform: translate(16px, 10px) !important;
}

.MuiOutlinedInput-root legend {
	max-width: 0px !important;
}

.MuiFormLabel-root {
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 20px !important;
	color: #83888a !important;
}

.MuiFormHelperText-contained {
	margin-left: 0px !important;
}

.MuiCheckbox-root {
	padding: 0px !important;
	padding-right: 8px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: #00465d !important;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 10px;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: #ccc;
	border: 0px none #ffffff;
}

.scroll::-webkit-scrollbar-track:hover {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-track:active {
	background: #c0c0c0;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
}

.hh-32 {
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 40px;
}

.hh-24 {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
}

.hh-20 {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}

.tt-18 {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
}

.tt-16 {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.tt-14 {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.tt-12 {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

.tt-10 {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
}

.color-blue {
	color: #006484;
}

.color-green {
	color: #2aca9a;
}

.color-gray-100 {
	color: #101415;
}

.color-gray-200 {
	color: #2f3435;
}

.color-gray-300 {
	color: #575f60;
}

.color-gray-400 {
	color: #727778;
}

.color-gray-500 {
	color: #83888a;
}

.color-gray-600 {
	color: #bcc2c3;
}

.color-gray-700 {
	color: #dce1e2;
}

.color-gray-800 {
	color: #e5e9ea;
}

.color-gray-900 {
	color: #f9fafa;
}

.color-white {
	color: #ffffff;
}

.color-black {
	color: #000000;
}

.color-primary {
	color: #223d48;
}

.bold {
	font-weight: 700;
}

.semibold {
	font-weight: 600;
}

.medium {
	font-weight: 500;
}

.regular {
	font-weight: 400;
}

.uppercase {
	text-transform: uppercase;
}

.button-primary {
	width: 100%;
	padding: 14px 0px;
	background: #223d48;
	color: #ffffff;
	border-radius: 8px;
	border: none;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.button-secondary {
	width: 100%;
	padding: 14px 0px;
	background: #e2eaee;
	color: #101415;
	border-radius: 8px;
	border: none;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.button-transparent {
	width: 100%;
	padding-top: 17px;
	padding-bottom: 19px;
	background: transparent;
	color: #bcc2c4;
	border: 1px solid #00465d;
	border-radius: 12px;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.button-no-style {
	width: 100%;
	border: none;
	background: none;
}

.form-group {
	margin-bottom: 12px;
}

input[type="file"] {
	display: none;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.MuiInputBase-input::placeholder {
	color: #727778 !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 20px !important;
}

.MuiInputBase-input {
	padding: 6px 0 9px !important;
}
